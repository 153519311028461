import React from 'react'
import Helmet from 'react-helmet';
import ContactBar from '../components/ContactBar';
import Contact from '../components/Contact';
// import BookNow from '../components/BookNow';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import GoogleMap from '../library/GoogleMap';


const KontaktPage = (props) => (
  <Layout location={props.location}>
    <div id="kontakt" className="page">
      <Helmet>
        <title>Kontakt - TCM Praxis Paola Nakahara-Hoffmann</title>
        <meta name="description" content="Kontakt, Praxis für Traditionelle Chinesische Medizin in Zürich Oerlikon" />
        <meta property="og:title" content="Kontakt - TCM Praxis Paola Nakahara-Hoffmann" />
        <meta property="og:description" content="Kontakt, Praxis für Traditionelle Chinesische Medizin" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <h1>PRAXIS FÜR TRADITIONELLE CHINESISCHE MEDIZIN</h1>
      <Contact />
      <GoogleMap
        apiKey="AIzaSyA5M5gfTWeTgHKwUXQ7P0EkJaeL-dp93T8"
      />
      <div id="coachfrog-booking"><iframe title="coachfrog" src="https://www.coachfrog.ch/coach_toolkit_booking/tcmpaolahoffmannch/?lang=de" frameBorder="0" width="100%" height="715px" style={{display: "inline-block"}}></iframe></div>
      <Footer />
    </div>
  </Layout>
)

export default KontaktPage;
