import React from 'react';
import PropTypes from 'prop-types';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';


class GoogleMap extends React.Component {
  
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    language: PropTypes.string,
  };
  
  static defaultProps = {
    language: 'de',
  };
  
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
  }
  
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  
  onReady(props, map) {
    console.log(props, map)
  }
  
  render() {
    const praxisCoord = { lat: 47.409531, lng: 8.542128 };
        
    const content = []
    
    const praxisMarker = <Marker
      key={'praxis'}
      title={'TCM Praxis Paola Nakahara'}
      name={'TCM Praxis Paola Nakahara'}
      street={'Baumackerstrasse 49'}
      city={'8050 Zürich Oerlikon'}
      onClick={this.onMarkerClick}
      position={praxisCoord} />
    
    const praxisInfo = <InfoWindow
      key={'info'}
      marker={this.state.activeMarker}
      visible={this.state.showingInfoWindow}>
        <div className="info-window">
          <div className="name">{this.state.selectedPlace.name}</div>
          <div className="address">{this.state.selectedPlace.street}</div>
          <div className="city">{this.state.selectedPlace.city}</div>
        </div>
      </InfoWindow>
    
    content.push(praxisMarker);
    content.push(praxisInfo);
    
    return (
      <div className='google-map'>
        <Map google={this.props.google}
          onReady={this.onReady}
          zoom={15}
          initialCenter={praxisCoord}
          ref={(ref) => { this.map = ref; }}
          onClick={this.onMapClicked}
        >
          {content}
        </Map>
      </div>
    )
    
  }
  
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: props.apiKey,
    language: props.language,
  }
))(GoogleMap)